<template>
  <b-container id="error-403">
    <h1>{{ $t('errors.403-forbidden-title') }}</h1>
    <p>{{ $t('errors.403-forbidden-message') }}</p>
    <p>
      <a href="/">{{ $t('errors.goHome-link') }}</a>
    </p>
  </b-container>
</template>

<script>
export default {
  name: 'Error403Forbidden'
}
</script>
